import ShuffleText from 'shuffle-text';
import Vivus from 'vivus';
import inview from "jquery-inview";
import ScrollMagic from "scrollmagic";

$(function () {

	new Vivus("background__f", { start: 'autostart', duration: 100 });
	new Vivus("background__l", { start: 'autostart', duration: 100 });
	new Vivus("background__i", { start: 'autostart', duration: 100 });
	new Vivus("background__g", { start: 'autostart', duration: 100 });
	new Vivus("background__f_sp", { start: 'autostart', duration: 100 });
	new Vivus("background__l_sp", { start: 'autostart', duration: 100 });
	new Vivus("background__i_sp", { start: 'autostart', duration: 100 });
	new Vivus("background__g_sp", { start: 'autostart', duration: 100 });

	if ($(".toppage__mainimage__logo").length) {
		setTimeout(() => {
			$(".toppage__mainimage__logo").addClass("is-anime");
			$(".toppage__mainimage__text").addClass("is-active");
			var effectList = [];
			var elementList = document.querySelectorAll('.toppage__mainimage__text span');

			for (var i = 0; i < elementList.length; i++) {

				var element = elementList[i];
				element.dataset.index = i;

				effectList[i] = new ShuffleText(element);

				effectList[i].duration = 1000;
				effectList[i].emptyCharacter = ' ';
				effectList[i].start();
			}
			new Vivus("logo", { duration: 100, type: 'sync' }, function () {
				setTimeout(() => {
					$(".toppage__mainimage__logo").addClass("is-active");
				}, 200);
			});
		}, 800);
	}
	$('.footer').on('inview', function (event, isInView) {
		if (isInView) {
			$(".footer").addClass("is-active");
		} else {
			$(".footer").removeClass("is-active");
		}
	});

	var controller = new ScrollMagic.Controller();
	if ($(".toppage__about").length) {
		if ($(window).width() >= 1280) {
			var scene = new ScrollMagic.Scene({
				triggerElement: ".toppage__about",
				triggerHook: "onLeave",
			})
				.setClassToggle(".corner,.footer,.nav,.header", "is-open")
				.addTo(controller);
		} else {
			var scene = new ScrollMagic.Scene({
				triggerElement: ".toppage__about",
				triggerHook: "onLeave",
				offset: -200
			})
				.setClassToggle(".corner,.footer,.nav,.header", "is-open")
				.addTo(controller);
		}
	} else {
		$(".corner,.footer,.nav,.header").addClass("is-open");
	}

	$(document).on('click', '.header__bar', function () {
		$('.header__bar,.nav').toggleClass('is-active');
	})
});
