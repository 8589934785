import ScrollMagic from "scrollmagic";
/**
 * 単純なサンプル
 * moment.jsを使って現在日付をconsole出力するだけ
 */
$(function () {
	if ($(window).width() >= 1280) {

		$(".scale").css({
			"transform": "scale(" + ($(window).width() / 1280) + ")"
		})
		$(".background div").css({
			"transform": "scale(" + ($(window).width() / 1280) + ")"
		})
	} else if (375 <= $(window).width() && $(window).width() <= 640) {
		$(".scale").css({
			"transform": "scale(" + ($(window).width() / 375) + ")"
		})
		$(".background div").css({
			"transform": "scale(" + ($(window).width() / 375) + ")"
		})
	}
});
$(window).on("load", function () {
	if ($(window).width() >= 1280) {
		$(".scalebox").css({
			height: $(".scale").height() * ($(window).width() / 1280)
		})
	} else if (375 <= $(window).width() && $(window).width() <= 640) {
		$(".scalebox").css({
			height: $(".scale").height() * ($(window).width() / 375)
		})
	}
})
$(window).resize(function () {
	if ($(window).width() >= 1280) {
		$(".scale").css({
			"transform": "scale(" + ($(window).width() / 1280) + ")"
		})
		$(".background div").css({
			"transform": "scale(" + ($(window).width() / 1280) + ")"
		})
		$(".scalebox").css({
			height: $(".scale").height() * ($(window).width() / 1280)
		})
	} else if (375 <= $(window).width() && $(window).width() <= 640) {
		$(".scale").css({
			"transform": "scale(" + ($(window).width() / 375) + ")"
		})
		$(".background div").css({
			"transform": "scale(" + ($(window).width() / 375) + ")"
		})
		$(".scalebox").css({
			height: $(".scale").height() * ($(window).width() / 375)
		})
	} else {
		$(".scale,.scalebox,.background div").attr("style", '');
	}
})
$(window).on("load", function () {
	if ($(".is-animation").length) {
		let scrollAnimation = new ScrollAddClass();
		scrollAnimation.init({
			target: '.is-animation',
			triggerHook: 'onCenter',
			offset: -200,
			reverse: true
		});
		/*
		if ($(window).width() >= 768) {
		} else {
			var target1 = $(".toppage__about__photo");
			var targetPosOT1 = target1.offset().top;
			var targetFactor = 0.2;
			var windowH = $(window).height();
			var scrollYStart1 = targetPosOT1 - windowH;
			target1.css('background-size', windowH);
			$(window).on('scroll', function () {
				var scrollY = $(this).scrollTop();
				if (scrollY > scrollYStart1) {
					//target1.css('background-position-y', (scrollY - targetPosOT1) * targetFactor + 'px');
					target1.css('background-position-y', (scrollY - targetPosOT1) * targetFactor + 'px');
				} else {
					target1.css('background-position', 'center top');
				}
			});
	}
			*/
	}
})


class ScrollAddClass {

	//初期値を設定
	constructor() {
		this.controller = new ScrollMagic.Controller();
		this.settings = {
			target: ".js-scrollAnimation",//アニメーションさせたい要素
			addClassName: "is-active",//アニメーションさせたい要素に付与するclass
			triggerHook: "onEnter",//発火タイミング：画面下"onEnter"、画面中央"onCenter"、画面上"onLeave""
			offset: 0,//100だと100px早く発火-100だと-100px遅く発火
			reverse: false
		}
	}

	//実行
	init(options) {
		this.setup(options);
		this.attachEvent(this.controller);
	}

	//外部から入力された設定をマージ
	setup(options) {
		this.settings = Object.assign({
			target: this.settings.target,
			addClassName: this.settings.addClassName,
			triggerHook: this.settings.triggerHook,
			offset: this.settings.offset,
			reverse: this.settings.reverse
		}, options || {});
	}

	//ScrollMagicを実行
	attachEvent(controller) {
		let targets = document.querySelectorAll(this.settings.target);
		let addClass = this.settings.addClassName;
		if (targets.length == 0) {
			return;
		}
		for (let target of targets) {

			let scene = new ScrollMagic.Scene({
				triggerElement: target,
				triggerHook: this.settings.triggerHook,
				offset: this.settings.offset,
				reverse: this.settings.reverse
			})
				.addTo(controller);

			scene.on('enter', () => {
				target.classList.add(this.settings.addClassName);
			});
			scene.on("leave", function (event) {
				if (event.state == "BEFORE") {
					console.log(addClass);
					target.classList.remove(addClass);
				}
			});
		}
	}
}
